import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import tw from "twin.macro"
import {
  TemplateProps,
  TenantPortalHowToMakeAPaymentTenantPayPageData,
} from "../templates"
import {
  TenantsHeadline,
  TenantsPayAssistance,
  TenantsPayMakeAPayment,
  TenantsPayHowTo,
} from "../../components/Tenants"
import { Image } from "../../components/Image"

const TenantPay: React.FC<
  TemplateProps<TenantPortalHowToMakeAPaymentTenantPayPageData>
> = ({ data }) => {
  const { title, content, featuredImage, template } = data?.wpPage
  const {
    howToMakeAPaymentSection: {
      paymentSteps,
      assistanceDetails,
      assistancePhoneNumber,
    },
    makeAPaymentSection,
  } = template.tenantPayPage

  const image = getImage(
    featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  )

  return (
    <section>
      <Image
        src={image}
        publicURL={featuredImage?.node?.localFile?.publicURL}
        alt=""
        role="presentation"
        css={[tw`w-full h-80`]}
        objectPosition="22% 100%"
      />
      <div
        css={[
          {
            backgroundImage: "url('/airport-interior.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          },
          tw`md:bg-cover`,
        ]}
      >
        <div css={[tw`mx-auto`]}>
          <TenantsHeadline
            wrapperStyles={[tw`md:mx-20`]}
            title={title}
            content={content}
            line={false}
          />
          <TenantsPayHowTo steps={paymentSteps} />
          <TenantsPayAssistance
            phoneNumberText={assistancePhoneNumber?.title}
            phoneNumberUrl={assistancePhoneNumber?.url}
            description={assistanceDetails}
          />
          <TenantsPayMakeAPayment {...makeAPaymentSection} />
        </div>
      </div>
    </section>
  )
}

export default TenantPay

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      template {
        ... on WpTemplate_TenantPay {
          tenantPayPage {
            howToMakeAPaymentSection {
              assistanceDetails
              assistancePhoneNumber {
                url
                title
              }
              paymentSteps {
                backgroundImage {
                  id
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                title
                description
                icon {
                  localFile {
                    publicURL
                  }
                  description
                  title
                }
              }
            }
            makeAPaymentSection {
              paymentMethodLogos {
                logo {
                  id
                  publicUrl
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              description
              headline
              links {
                link {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
